import { PlusCircleIcon as AddIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import a from 'indefinite'
import { useRecoilState } from 'recoil'
import { isUserAdminState } from '../store/DomainStore'
import DropdownLinks from './DropdownLinks'
import IconWithTitle from './icons/IconWithTitle'

function getNewAccountLinks() {
    return [
        // DS, 2024-10-22: X Changed their mind again so I've undone the change outlined below.
        // DC, 2024-10-17: X have changed our Pro Plan to limit the number of authorised X profiles.
        // See https://arwen-ai.atlassian.net/browse/AA-1014
        // We've decided to disable adding new X profiles.
        // You can navigate to /account/new/twitter to create a new profile if you have to.
        {
            title: 'Twitter/X profile',
            to: '/account/new/twitter',
            icon: <IconWithTitle icon="fab fa-x-twitter text-4xl mb-2" />,
            isAdminLink: false
        },
        {
            title: 'Facebook page',
            to: '/account/new/facebook_page',
            icon: <IconWithTitle icon="fab fa-facebook text-4xl mb-2" />,
            isAdminLink: false
        },
        {
            title: 'YouTube channel',
            to: '/account/new/youtube',
            icon: <IconWithTitle icon="fab fa-youtube text-4xl mb-2" />,
            isAdminLink: false
        },
        {
            title: 'Instagram profile',
            to: '/account/new/instagram',
            icon: <IconWithTitle icon="fab fa-instagram text-4xl mb-2" />,
            isAdminLink: false
        },
        {
            title: 'Instagram profile (direct auth, admin only)',
            to: '/account/new/instagram_direct',
            icon: <IconWithTitle icon="fab fa-instagram text-4xl mb-2" />,
            isAdminLink: true
        },
        {
            title: 'TikTok Ad Account',
            to: '/account/new/tiktok_ad',
            icon: <IconWithTitle icon="fab fa-tiktok text-4xl" title="Ad" />,
            isAdminLink: false
        },
        {
            title: 'TikTok User Account',
            to: '/account/new/tiktok_user',
            icon: <IconWithTitle icon="fab fa-tiktok text-4xl" title="User" />,
            isAdminLink: false
        }
    ]
}

/**
 *This function is not used anywhere in the project. Is it necessary or can we delete it?
 */
function NewAccountDropDown() {
    const newAccountLinks = getNewAccountLinks()

    return (
        <DropdownLinks
            title="Add social profile"
            links={newAccountLinks}
            icon={<AddIcon className="w-5 h-5 text-gray-950" />}
        />
    )
}

export function NewAccountButtons() {
    const [admin] = useRecoilState(isUserAdminState)
    const newAccountLinks = getNewAccountLinks()
    const sortedAccountLinks = newAccountLinks.filter((link) => {
        if (!admin && link.isAdminLink) {
            return false
        }
        return true
    })

    return (
        <div className="flex flex-row justify-center flex-wrap">
            {sortedAccountLinks.map((link) => {
                return (
                    <div
                        key={link.title}
                        className="flex flex-col items-center m-4"
                    >
                        <Link
                            to={link.to}
                            type="button"
                            className={`
                                flex
                                flex-col
                                items-center
                                justify-center
                                relative
                                w-36
                                h-36
                                border
                                border-gray-300
                                border-solid
                                rounded-md
                                p-4
                                text-center
                                shadow
                                hover:border-gray-400
                                hover:bg-gray-100
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-primary-500
                                no-underline
                            `}
                            data-cy={`goto_add_${link.title.toLowerCase()}_button`}
                        >
                            {link.icon}
                            <span className="text-sm font-medium text-gray-900">
                                Add {a(link.title)}
                            </span>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}

export default NewAccountDropDown
