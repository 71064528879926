import InstagramDirectAuthorisationButton from '../../components/authorise/platforms/InstagramDirectAuthorisationButton'
import { TextPageContainer } from '../../containers/TextPageContainer'
import { TextPageHeader } from '../../containers/TextPageHeader'

/**
 * This page is used to add an Instagram account to Arwen using authorisation against the Instagram API not the Facebook API. T
 */
function NewInstagramAccountPage() {
    return (
        <TextPageContainer>
            <TextPageHeader title="Add Instagram profile" />
            <p>Click below to add an Instagram profile to Arwen. </p>

            <InstagramDirectAuthorisationButton />
        </TextPageContainer>
    )
}

export default NewInstagramAccountPage
