/**
 * This button starts the OAuth flow for Instagram.
 */

import {
    SocialMediaServiceEnum,
    useGetOauthUrlQuery
} from '../../../api/client'
import SimpleLoader from '../../SimpleLoader'

function InstagramDirectAuthorisationButton() {
    const baseUrl = window.location.protocol + '//' + window.location.host
    const { data, loading } = useGetOauthUrlQuery({
        variables: {
            socialMediaService: SocialMediaServiceEnum.Instagram,
            baseUrl: baseUrl
        }
    })

    let authorisationUrl: string | undefined = data?.getOauthUrl
    return (
        <>
            {loading ? (
                <SimpleLoader loading={loading} />
            ) : (
                <a href={authorisationUrl}>Connect your Instagram profile</a>
            )}
        </>
    )
}

export default InstagramDirectAuthorisationButton
